<template>
    <div class="p-personal-password">
        <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-width="100px"
            v-loading="isLoading"
        >
            <el-form-item label="当前用户"
                ><span v-text="form.email"></span
            ></el-form-item>
            <el-form-item label="新密码" prop="pwd1">
                <el-input
                    v-model="form.pwd1"
                    type="password"
                    maxlength="32"
                ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="pwd2">
                <el-input
                    v-model="form.pwd2"
                    type="password"
                    maxlength="32"
                ></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-button type="primary" @click="submit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<style lang="less"></style>

<script>
let instance;

export default {
    components: {},
    data() {
        return {
            ready: false,
            isLoading: false,
            form: {
                id: "",
                email: "",
                pwd1: "",
                pwd2: "",
            },
            rules: {
                pwd1: [
                    {
                        required: true,
                        message: "请输入新密码",
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        max: 32,
                        message: "密码长度: 6 ~ 32",
                        trigger: "blur",
                    },
                ],
                pwd2: [
                    {
                        required: true,
                        message: "请确认新密码",
                        trigger: "blur",
                    },
                    {
                        validator: function (rule, value, callback) {
                            if (instance.form.pwd1 === instance.form.pwd2) {
                                callback();
                            } else {
                                callback(new Error("请输入相同的密码"));
                            }
                        },
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    computed: {},
    mounted() {
        instance = this;
        let user = this.$store.state.user;
        this.form.id = user.id;
        this.form.email = user.email;
    },
    methods: {
        submit() {
            let that = this;
            that.$refs["form"].validate((valid) => {
                if (!valid) {
                    return;
                }

                if (that.isLoading) {
                    return;
                }
                let param = Object.assign({}, that.form);
                that.isLoading = true;
                that.$ajax("/account/change-password", param)
                    .done(function () {
                        that.form.pwd1 = "";
                        that.form.pwd2 = "";
                        that.$alert("修改成功");
                    })
                    .fail(function () {
                        //
                    })
                    .always(function () {
                        that.isLoading = false;
                    });
            });
        },
    },
    filters: {},
};
</script>
